//**************************************************************
// GSAP ANIMATION
//**************************************************************
// COMMON ANIMATION
// ==================================================
// FADE IN UP ------------------------------//
var target = gsap.utils.toArray(".fadeInUp");
gsap.utils.toArray(".fadeInUp").forEach((target) => {
  gsap.timeline({
    scrollTrigger: {
      trigger: target,
      start: "top 80%",
      end: "bottom 80%",
      toggleActions: "play none none none"
    }
  })
    .from(target, {
      y: 60, autoAlpha: 0, scale: 0.9, stagger: 0.5,
    })
    .to(target, {
      y: 0, autoAlpha: 1, scale: 1, ease: "expo.inOut", duration: 1,
    })
});

// FADE IN UP（タイトルテンプレート） ------------------------------//
var target = gsap.utils.toArray(".title-template");
gsap.utils.toArray(".title-template").forEach((target) => {
  gsap.timeline({
    scrollTrigger: {
      trigger: target,
      start: "top 80%",
      end: "bottom 80%",
      toggleActions: "play none none none"
    }
  })
    .from(target, {
      y: 60, autoAlpha: 0, scale: 0.95, stagger: 0.5,
    })
    .to(target, {
      y: 0, autoAlpha: 1, scale: 1, ease: "expo.inOut", duration: 1,
    })
});

// FADE IN SCALE ------------------------------//
var target = gsap.utils.toArray(".fadeInScale");
target.forEach((targetElement) => {
  gsap.timeline({
    scrollTrigger: {
      trigger: targetElement,
      start: "top 80%",
      end: "bottom 80%",
      toggleActions: "play none none none"
    }
  })
    .from(targetElement, {
      ease: "ease.inOut",
      autoAlpha: 0,
      scale: 0.5,
      duration: 0.2,
    })
    .to(targetElement, {
      duration: 0.2,
      scale: 1,
      autoAlpha: 1,
    });
});

// FADE IN LEFT ------------------------------//
var target = gsap.utils.toArray(".fadeInLeft");
gsap.utils.toArray(".fadeInLeft").forEach((target) => {
  gsap.timeline({
    scrollTrigger: {
      trigger: target,
      start: "top 80%",
      end: "bottom 80%",
      toggleActions: "play none none none"
    }
  })
    .from(target, {
      x: -60, autoAlpha: 0, stagger: 0.5,
    })
    .to(target, {
      x: 0, autoAlpha: 1, ease: "expo.inOut", duration: 1,
    })
});

// FADE IN RIGHT ------------------------------//
var target = gsap.utils.toArray(".fadeInRight");
gsap.utils.toArray(".fadeInRight").forEach((target) => {
  gsap.timeline({
    scrollTrigger: {
      trigger: target,
      start: "top 80%",
      end: "bottom 80%",
      toggleActions: "play none none none"
    }
  })
    .from(target, {
      x: 60, autoAlpha: 0, stagger: 0.5,
    })
    .to(target, {
      x: 0, autoAlpha: 1, ease: "expo.inOut", duration: 1,
    })
});


// FADE IN UP STAGGER（順番に表示させる） ------------------------------//
// gsap.fromTo('.fadeInUp-stagger',
//   { y: 50, autoAlpha: 0, scale: 0.9 },
//   {
//     y: 0, autoAlpha: 1, scale: 1,
//     stagger: 0.2,
//     ease: "expo.inOut",
//     duration: 1,
//     scrollTrigger: {
//       trigger: '.fadeInUp-stagger',
//       start: "top 80%",
//       end: "bottom 80%",
//       toggleActions: "play none none none"
//     }
//   }
// );

// FADE IN UP STAGGER（順番に表示させる） ------------------------------//
//トリガーはfadeInUp-stagger
gsap.utils.toArray(".fadeInUp-stagger").forEach((target) => {
  const radiusBoxes = target.querySelectorAll('.radius-box,.training-menu-box,.flow-contents,.pc-logo,.sp-logo');
  //まずはボックス自体を非表示にする
  gsap.set(radiusBoxes, { y: 50, autoAlpha: 0, scale: 0.9 });

  gsap.fromTo(
    radiusBoxes,
    {
      y: 50,
      autoAlpha: 0,
      scale: 0.9,
    },
    {
      y: 0,
      autoAlpha: 1,
      scale: 1,
      stagger: 0.1,
      ease: "expo.inOut",
      duration: 1,
      scrollTrigger: {
        trigger: target,
        start: "top 80%",
        end: "bottom 80%",
        toggleActions: "play none none none",
      },
    }
  );
});


gsap.utils.toArray(".page-link-nav").forEach((target) => {
  const navBtn = target.querySelectorAll('.btn-page-link');
  //まずはボックス自体を非表示にする
  gsap.set(navBtn, { y: 50, autoAlpha: 0, scale: 0.9 });

  gsap.fromTo(
    navBtn,
    {
      y: 50,
      autoAlpha: 0,
      scale: 0.9,
    },
    {
      y: 0,
      autoAlpha: 1,
      scale: 1,
      stagger: 0.2,
      ease: "expo.inOut",
      duration: 1,
      scrollTrigger: {
        trigger: target,
        start: "top 80%",
        end: "bottom 80%",
        toggleActions: "play none none none",
      },
    }
  );
});


// FADE IN UP STAGGER（無限横スクロールアニメーション） ------------------------------//
// gsap.to('.scroll-box img', {
//   xPercent: 100,
//   duration: 15,
//   repeat: -1,
//   ease: 'none',
// });



